// Routing
import { useHistory } from 'react-router-dom'
// Algolia
import algoliasearch from 'algoliasearch'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
// Global - Config
import { algoliaApiKey, algoliaApplicationId, algoliaIndex } from 'config'
// Local - Components
import { Autocomplete, ProductItem } from './components'
import { useMemo } from 'react'

// Prepare a new Algolia Search Client
const searchClient = algoliasearch(algoliaApplicationId, algoliaApiKey)

const SearchLive: React.FC = () => {
  // Routing
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const getRedirectURL = (query) => {
    if (searchParams.get('order')) {
      return `/shop/?order=${searchParams.get('order')}&query=${query}`
    }
    return `/shop/?query=${query}`
  }

  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 5,
    transformSource({ source }) {
      return {
        ...source,
        onSelect: ({ state }) => {
          history.push(getRedirectURL(state.query))
        }
      }
    }
  })

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: `${algoliaIndex}_query_suggestions`,
    transformSource({ source }) {
      return {
        ...source,
        onSelect: ({ state }) => {
          history.push(getRedirectURL(state.query))
        }
      }
    }
  })

  return (
    <>
      <Autocomplete
        debug={false}
        detachedMediaQuery=''
        getSources={({ query }) => [
          {
            sourceId: 'products',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: algoliaIndex,
                    query
                  }
                ]
              })
            },
            onSelect: ({ state }) => {
              history.push(`/shop/?query=${state.query}`)
            },
            templates: {
              item({ item, components }) {
                return <ProductItem components={components} hit={item} />
              }
            }
          }
        ]}
        insights={true}
        onStateChange={({ state }) => {
          console.log('state', state)
        }}
        onSubmit={({ state }) => {
          history.push(`/shop/?query=${state.query}`)
        }}
        openOnFocus={true}
        placeholder='Search through products, categories and suppliers... '
        querySuggestionsPlugin={querySuggestionsPlugin}
        recentSearchesPlugin={recentSearchesPlugin}
      />
    </>
  )
}

export { SearchLive }
