// React
import { createElement, Fragment, useEffect, useRef } from 'react'
import { render } from 'react-dom'
// Algolia
import '@algolia/autocomplete-theme-classic'
import { autocomplete } from '@algolia/autocomplete-js'

export function Autocomplete(props) {
  const containerRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) {
      return undefined
    }

    const search = autocomplete({
      container: containerRef.current,
      plugins: [props.recentSearchesPlugin, props.querySuggestionsPlugin],
      renderer: { createElement, Fragment, render },
      ...props
    })

    return () => {
      search.destroy()
    }
  }, [props])

  return <div ref={containerRef} />
}
