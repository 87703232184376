// MainStem - UI
import { theme } from '@mainstem/react-mainstem'
// Icons
import {
  faBook,
  faBrowser,
  faChartLine,
  faFileLines,
  faShippingFast,
  faBoxes,
  faCalendar,
  faCogs,
  faFileSearch,
  faInventory,
  faLink,
  faSitemap
} from '@fortawesome/pro-light-svg-icons'
// MainStem - API
import { MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse } from 'api-new'
// Global - Helpers
import { permissionCheck } from 'helpers'

export const links = async (loggedInUser: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse) => {
  const hasIntegrationSettingsAccess = permissionCheck(loggedInUser, 'Settings', 'ViewIntegrations', 'true')

  const hasPunchOutSettingsAccess = permissionCheck(loggedInUser, 'Settings', 'ViewPunchouts', 'true')

  const canShopPunchOuts = permissionCheck(loggedInUser, 'Marketplace', 'AllowPunchOuts', 'true')

  const hasAccountSettingsAccess = permissionCheck(loggedInUser, 'Settings', 'ViewAccountSettings', 'true')

  const hasDirectPunchOut = loggedInUser.organization?.allowPunchOutInMainNav

  const canUpdateFinancialDimensions = permissionCheck(loggedInUser, 'Settings', 'EditFinancialDimensions', 'true')

  const hasAnySettingsAccess =
    hasIntegrationSettingsAccess ||
    hasPunchOutSettingsAccess ||
    hasAccountSettingsAccess ||
    canUpdateFinancialDimensions

  const navigationLinks = [
    {
      title: 'Home',
      to: '/dashboard/marketplace',
      icon: faChartLine,
      bases: ['/dashboard/']
    },
    {
      icon: faSitemap,
      title: 'Organization',
      bases: ['/catalogs', '/departments', '/locations', '/organization', '/users'],
      links: [
        {
          title: 'Locations',
          to: '/locations/dashboard',
          icon: theme.icons.mainstem.locations,
          bases: ['/locations'],
          classes: {
            nav: 'onboard-locations'
          }
        },
        {
          title: 'Departments',
          to: '/departments/dashboard',
          icon: theme.icons.mainstem.departments,
          bases: ['/departments']
        },
        {
          title: 'Users',
          to: '/users/dashboard',
          icon: theme.icons.mainstem.users,
          bases: ['/users'],
          classes: {
            nav: 'onboard-users'
          }
        },
        {
          title: 'Catalogs',
          to: '/catalogs/dashboard',
          icon: theme.icons.mainstem.catalogs,
          bases: ['/catalogs']
        }
      ]
    },
    {
      icon: faLink,
      title: 'Supply Chain',
      bases: ['/suppliers', '/products'],
      links: [
        {
          title: 'Suppliers',
          to: '/suppliers/dashboard',
          icon: theme.icons.mainstem.suppliers,
          bases: ['/suppliers']
        },
        {
          title: 'Products',
          to: '/products/dashboard',
          icon: theme.icons.mainstem.products,
          bases: ['/products']
        }
      ]
    },
    {
      icon: faBoxes,
      title: 'Purchasing',
      bases: ['/orders', '/requisitions', '/rfq', '/punch-outs/purchase', '/invoices/dashboard'],
      links: [
        {
          title: 'Requisitions',
          to: '/requisitions/dashboard',
          icon: theme.icons.mainstem.requisition,
          bases: ['/requisitions']
        },
        {
          title: 'Purchase Orders',
          to: '/purchase-orders/dashboard',
          icon: theme.icons.mainstem.orders,
          bases: ['/purchase-orders/dashboard']
        },
        {
          title: 'Invoices',
          to: '/invoices/dashboard',
          icon: theme.icons.mainstem.invoices,
          bases: ['/invoices/dashboard']
        },
        {
          title: 'Request for Quote',
          to: '/rfq/dashboard',
          icon: theme.icons.mainstem.rfq,
          bases: ['/rfq']
        },
        {
          title: 'Punch-Outs',
          to: '/punch-outs/purchase',
          icon: faBrowser,
          bases: ['/punch-outs']
        }
      ]
    },
    {
      icon: faInventory,
      title: 'Logistics',
      bases: ['/shipments', '/receiving'],
      links: [
        {
          title: 'Shipments',
          to: '/shipments/dashboard',
          icon: theme.icons.mainstem.shipments,
          bases: ['/shipments']
        },
        {
          title: 'Receiving',
          to: '/receiving/dashboard',
          icon: theme.icons.mainstem.receiving,
          bases: ['/receiving']
        }
      ]
    },
    {
      icon: faFileSearch,
      title: 'Reporting',
      bases: ['/reports'],
      links: [
        {
          title: 'Line Items',
          to: '/reports/line-items',
          icon: faFileLines,
          bases: ['/reports/line-items']
        },
        {
          title: 'Open Orders',
          to: '/reports/open-orders',
          icon: faShippingFast,
          bases: ['/reports/open-orders']
        },
        {
          title: 'Order History',
          to: '/reports/historical-orders',
          icon: faCalendar,
          bases: ['/reports/historical-orders']
        },
        {
          title: 'Punch Outs',
          to: '/reports/punch-outs',
          icon: faBrowser,
          bases: ['/reports/punch-outs']
        }
      ]
    },
    {
      icon: faCogs,
      title: 'Settings',
      bases: ['/integrations', '/punch-outs/settings'],
      links: [
        {
          title: 'Account',
          to: '/account',
          icon: faBook,
          bases: ['/account']
        },
        {
          title: 'Integrations',
          to: '/integrations/dashboard',
          icon: theme.icons.mainstem.integrations,
          bases: ['/integrations']
        },
        {
          title: 'Punch-Outs',
          to: '/punch-outs/settings',
          icon: faBrowser,
          bases: ['/punch-outs']
        }
      ]
    }
  ]

  if (canShopPunchOuts === false) {
    const purchasingLinkIndex = navigationLinks.findIndex((i) => i.title === 'Purchasing')
    const purchasingLinks = navigationLinks[purchasingLinkIndex].links as any

    // Remove PunchOuts Purchasing link
    const punchOutPurchasingLinkIndex = purchasingLinks.findIndex((i) => i.title === 'Punch-Outs')
    purchasingLinks.splice(punchOutPurchasingLinkIndex, 1)
  }

  // If users customer account is not allowed direct Punchout access, remove the link
  if (hasDirectPunchOut === false) {
    const purchasingLinkIndex = navigationLinks.findIndex((i) => i.title === 'Purchasing')
    const purchasingLinks = navigationLinks[purchasingLinkIndex].links as any

    // Remove PunchOuts Purchasing link
    const punchOutPurchasingLinkIndex = purchasingLinks.findIndex((i) => i.title === 'Punch-Outs')
    purchasingLinks.splice(punchOutPurchasingLinkIndex, 1)

    const settingsLinkIndex = navigationLinks.findIndex((i) => i.title === 'Settings')
    const settingsLinks = navigationLinks[settingsLinkIndex].links as any

    // Remove punch out link
    const punchOutSettingsLinkIndex = settingsLinks.findIndex((i) => i.title === 'Punch-Outs')
    settingsLinks.splice(punchOutSettingsLinkIndex, 1)
  }

  if (hasAnySettingsAccess) {
    const settingsLinkIndex = navigationLinks.findIndex((i) => i.title === 'Settings')
    const settingsLinks = navigationLinks[settingsLinkIndex].links as any
    // We leave dashboard alone in the links.
    if (!hasIntegrationSettingsAccess) {
      // Remove integrations link
      const integrationsLinkIndex = settingsLinks.findIndex((i) => i.title === 'Integrations')
      settingsLinks.splice(integrationsLinkIndex, 1)
    }
    if (hasDirectPunchOut && !hasPunchOutSettingsAccess) {
      // Remove punch out link
      const punchOutLinkIndex = settingsLinks.findIndex((i) => i.title === 'Punch-Outs')
      settingsLinks.splice(punchOutLinkIndex, 1)
    }
    if (!hasAccountSettingsAccess && !canUpdateFinancialDimensions) {
      // Remove Account link
      const accountSettingsLinkIndex = settingsLinks.findIndex((i) => i.title === 'Account')
      settingsLinks.splice(accountSettingsLinkIndex, 1)
    }
  } else {
    // Otherwise we remove all of it
    const settingsLinkIndex = navigationLinks.findIndex((i) => i.title === 'Settings')
    navigationLinks.splice(settingsLinkIndex, 1)
  }
  return navigationLinks
}
